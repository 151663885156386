import React, { useState, useEffect } from 'react';
import '../CSS/Sidebar.css';
import { Link } from 'react-router-dom';

// Sidebar component
const Sidebar = () => {
  const [role, setRole] = useState('');

  useEffect(() => {
    function updateVh() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  
    // Call on page load and window resize
    updateVh();
    window.addEventListener('resize', updateVh);
    
    const checkCookie = async () => {
      const result = await fetch(`/api/account/getCookie`);
      const cookie = await result.json();
      if (cookie.role) {
        setRole(cookie.role)
      }
    }
    checkCookie();
  }, [])

  const onSignOut = async () => {
    try{
      const result = await fetch(`/api/account/logout`)
      const logout = await result.json();
      if(logout.status == "success") window.location = "/account";
    } catch(error) {
      alert("Sign Out failed... Press 'OK' to refresh");
      window.location.reload(false);
    }
  };

  if (window.innerWidth >= 768) {
    return (
      <div className="sidebar_AL">
        <div className="logo_AL">
          <span><b>Singapore Ancestry</b></span>
          {/* <img src="/path-to-your-logo.png" /> */}
        </div>
        <div className="menu_AL">
        {role === 'SA' && (
            <>
              <Link to="/user_manage" className="link_AL"><MenuItem icon="🧑" text="User Management" /></Link>
              <Link to="/undertaker_manage" className="link_AL"><MenuItem icon="👤" text="Undertaker Mang." /></Link>
              <Link to="/obituary_manage" className="link_AL"><MenuItem icon="📄" text="Obituary" /></Link>
              {/* <Link to="/famtree_manage" className="link_AL"><MenuItem icon="🌳" text="Family Tree" /></Link> */}
              <Link to="/setting" className="link_AL"><MenuItem icon="⚙️" text="Setting" /></Link>
            </>
          )}
          {role === 'Undertaker' && (
            <>
              <Link to="/user_manage" className="link_AL"><MenuItem icon="🧑" text="User Management" /></Link>
              <Link to="/undertaker_profile" className="link_AL"><MenuItem icon="👤" text="Profile" /></Link>
              <Link to="/obituary_manage" className="link_AL"><MenuItem icon="📄" text="Obituary" /></Link>
              <Link to="/setting" className="link_AL"><MenuItem icon="⚙️" text="Setting" /></Link>
            </>
          )}
          {role === 'User' && (
            <>
              <Link to="/user_profile" className="link_AL"><MenuItem icon="👤" text="Profile" /></Link>
              <Link to="/obituary_manage" className="link_AL"><MenuItem icon="📄" text="Obituary" /></Link>
              {/* <Link to="/famtree_manage" className="link_AL"><MenuItem icon="🌳" text="Family Tree" /></Link> */}
              <Link to="/setting" className="link_AL"><MenuItem icon="⚙️" text="Setting" /></Link>
            </>
          )}
        </div>
        <div className="sign-out_AL">
          <button onClick={onSignOut}>Sign Out</button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="sidebar_AL">
        <div className="logo_AL">
          <span><b>Singapore Ancestry</b></span>
          {/* <img src="/path-to-your-logo.png" /> */}
        </div>
        <div className="menu_AL">
          {role === 'SA' && (
            <>
              <Link to="/user_manage" className="link_AL"><MenuItem icon="🧑" text="User Mang." /></Link>
              <Link to="/undertaker_manage" className="link_AL"><MenuItem icon="👤" text="Undertaker Mang." /></Link>
              <Link to="/obituary_manage" className="link_AL"><MenuItem icon="📄" text="Obituary" /></Link>
              {/* <Link to="/famtree_manage" className="link_AL"><MenuItem icon="🌳" text="Family Tree" /></Link> */}
              <Link to="/setting" className="link_AL"><MenuItem icon="⚙️" text="Setting" /></Link>
            </>
          )}
          {role === 'Undertaker' && (
            <>
              <Link to="/user_manage" className="link_AL"><MenuItem icon="🧑" text="User Mang." /></Link>
              <Link to="/undertaker_profile" className="link_AL"><MenuItem icon="👤" text="Profile" /></Link>
              <Link to="/obituary_manage" className="link_AL"><MenuItem icon="📄" text="Obituary" /></Link>
              <Link to="/setting" className="link_AL"><MenuItem icon="⚙️" text="Setting" /></Link>
            </>
          )}
          {role === 'User' && (
            <>
              <Link to="/user_profile" className="link_AL"><MenuItem icon="👤" text="Profile" /></Link>
              <Link to="/obituary_manage" className="link_AL"><MenuItem icon="📄" text="Obituary" /></Link>
              {/* <Link to="/famtree_manage" className="link_AL"><MenuItem icon="🌳" text="Family Tree" /></Link> */}
              <Link to="/setting" className="link_AL"><MenuItem icon="⚙️" text="Setting" /></Link>
            </>
          )}
        </div>
        <div className="sign-out_AL">
          <button onClick={onSignOut}>Sign Out</button>
        </div>
      </div>
    );
  }
};

// Reusable MenuItem component
const MenuItem = ({ icon, text }) => (
  <div className="menu-item_AL">
    <span className="icon_AL">{icon}</span>
    <span className="text_AL">{text}</span>
  </div>
);

export default Sidebar;