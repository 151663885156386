import '../CSS/UserReview.css';

const reviews = [
    {
        text: "The online obituary tool was easy to use and allowed us to create a beautiful, personalized tribute. Sharing it with family and friends brought us comfort and connection during a difficult time.",
        date: "2 weeks ago",
        name: "Gwen"
    },
    {
        text: "Creating a tribute with this tool was seamless and heartfelt. It made honoring my loved one both simple and special.",
        date: "2 weeks ago",
        name: "Anonymous"
    },
    {
        text: "The online obituary tool made it easy to craft a meaningful memorial. The process was intuitive, and sharing was effortless.",
        date: "2 weeks ago",
        name: "May Lo"
    }
];

const ForArrow = () => {
    if (window.innerWidth >= 768) {
      return (
        <div className="recent-obituaries-obituaries-container">
          <button className="user-reviews-nav-button"><img src='left arrow.png' alt='an arrow' /></button>
          {reviews.map((review, index) => (
                <div className="user-reviews-review-card" key={index}>
                    <div>
                        <p>"{review.text}"</p>
                    </div>
                    <div className="user-reviews-bottom-div">
                        <div>
                            <p className="user-reviews-review-date">{review.date}</p>
                        </div>
                        <div className='user-reviews-reviewer-name-div'>
                            <p className="user-reviews-reviewer-name">{review.name}</p>
                        </div>
                    </div>
                </div>
            ))}
          <button className="user-reviews-nav-button"><img src='right arrow.png' alt='an arrow' /></button>
        </div>
      )
    } else {
      return (
        <div className="recent-obituaries-obituaries-container">
          {reviews.map((review, index) => (
                    <div className="user-reviews-review-card" key={index}>
                        <div>
                            <p>"{review.text}"</p>
                        </div>
                        <div className="user-reviews-bottom-div">
                            <div>
                                <p className="user-reviews-review-date">{review.date}</p>
                            </div>
                            <div className='user-reviews-reviewer-name-div'>
                                <p className="user-reviews-reviewer-name">{review.name}</p>
                            </div>
                        </div>
                    </div>
                ))}
          <div className='recent-obituaries-nav-button-container'>
            <button className="user-reviews-nav-button"><img src='left arrow.png' alt='an arrow' /></button>
            <button className="user-reviews-nav-button"><img src='right arrow.png' alt='an arrow' /></button>
          </div>
        </div>
      )
    }
  }

const UserReview = () => {
    return (
        <div className="user-reviews">
            <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
            <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
            <h2 className='user-reviews-h2'>User Review</h2>
            <span className='user-reviews-span'>Hear from those who have used Eternal Tributes to honor their loved ones.</span>
            <ForArrow />
        </div>
    );
};

export default UserReview;