import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../CSS/Header.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="header">
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      <div className="logo">
        <span><b>Singapore Ancestry</b></span>
        {/* <img src="/path-to-your-logo.png" /> */}
      </div>
      <div className="side-by-side">
        <div className={`navbar ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/obituary">Obituary</Link></li>
            <li><Link to="/gallery">Gallery</Link></li>
            <li><Link to="/undertaker">Undertaker</Link></li>
          </ul>
        </div>
        <button className="menu-toggle" onClick={handleMenuToggle}>
          ☰
        </button>
        <div className="login-button">
        <Link to="/account"><button>Log In</button></Link>
        </div>
      </div>
    </div>
  );
};

export default Header;