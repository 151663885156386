import React from 'react';
import '../CSS/Account.css';

const WelcomeScreen = ({ onGetStarted, onLogin }) => {
  return (
    <div className="welcome-screen">
      <header className="header_acc">
        <h2 className='h2_acc'>Get Started With Singapore Ancestry</h2>
      </header>
      <div className="content_acc">
        <h3 className='h3_acc'>Hey!</h3>
        <h3 className='h3_acc'>Welcome To Singapore Ancestry</h3>
        <p className='p_acc'>Log in to access your profile.</p>
        <div className='flex_acc'>
          <button onClick={onGetStarted} className="btn-primary_acc">Get Started</button>
        </div>
        <div className='flex_acc'>
          <button onClick={onLogin} className="btn-secondary_acc">I already have an account</button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
