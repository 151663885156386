import React, { useEffect, useState } from 'react';
import '../CSS/SettingPage.css';
import Sidebar from '../Component/Sidebar';

const SettingsForm = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hashPassword, setHashPassword] = useState('');

  const [email, setEmail] = useState('');

  useEffect(() => {
    const checkCookie = async () => {
      const result = await fetch(`/api/account/getCookie`);
      const cookie = await result.json();
      if (cookie.email) {
        setEmail(cookie.email)
      }
      if (cookie.role) {
    
      }
    }
    checkCookie();
  }, [])

  useEffect(() => {
    const triggerCreate = async () => {
      const response = await fetch(`/api/account/updatePassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          email: email,
          password: hashPassword
        }),
      });
      if (response.status == 200) {
        alert("Password updated successful!");
      }
      else {
        alert("Password update failed!");
      }
      window.location = "/setting";
    }
    
    if(hashPassword !="") {
      triggerCreate();
    }
  }, [hashPassword]);

  const checkCredential = async () => {
    try{
      if(oldPassword && newPassword && confirmPassword) {
        if (newPassword !== confirmPassword) {
          alert("Passwords do not match!");
          return;
        }
        const getAcc = await fetch(`/api/account/specificAccount?email=${email}`)
        const account = await getAcc.json()
        if(account.length > 0) await getHash(account[0].password)
      } else alert("Please enter all required fields")
    } catch(error) {
      alert("CheckCredential failed... Press 'OK' to refresh");
      window.location.reload(false);
    }
  };

  async function getHash(hash) {
    try{
      const result = await fetch(`/api/account/checkPW?password=${oldPassword}&hash=${hash}`)
      const compare = await result.json()
      if(compare[0].result === true) {
        await hashPW();
      } else {
        alert("Wrong Old Password")
      }
    } catch(error) {
      alert("GetHash failed... Press 'OK' to refresh");
      window.location.reload(false);
    }
  };

  const hashPW = async () => {
    try {
      const response = await fetch(`/api/account/hashPW?password=${newPassword}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setHashPassword(data);
    } catch (error) {
      alert("Error fetching hashed password:", error);
    }
  }

  const handleBack = async () => {
    window.location = "/setting"
  }
  const handleCancel = async () => {
    window.location.reload(false)
  }
  const handleSave = async () => {
    await checkCredential();
  };

  return (
    <div className="settings-main-content">
      <div className='settings-header-container'>
        <div className='settings-title'>
          <h1>Setting</h1>
        </div>
        <div className="settings-header">
          <span className="settings-back-button" onClick={handleBack}>← Back</span>
          <div className="settings-form-actions">
            <button className="settings-cancel-button" onClick={handleCancel}>Cancel</button>
            <button className="settings-save-button" onClick={handleSave}>Save</button>
          </div>
        </div>
      </div>
      <div className='setting-body'>
        <div className='setting-side-by-side'>
          <div className='settings-side_description'>
            <h2>Setting</h2>
            <p>Change your password.</p>
          </div>
          <div className="settings-form">
            <div className="settings-form-group">
              <label>Old Password</label>
              <input
                type="password"
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="settings-form-group">
              <label>New Password</label>
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="settings-form-group">
              <label>Confirm new password</label>
              <input
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

// Main Settings Page Component
const SettingsPage = () => (
  <div className="settings-page">
    <Sidebar />
    <SettingsForm />
  </div>
);

export default SettingsPage;
