import React, { useState, useEffect } from 'react';
import '../CSS/UserForm.css';
import Sidebar from '../Component/Sidebar';

const UsersForm = () => {
  const [userName, setUserName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [UContactNo, setUContactNo] = useState('');

  const [accStatus, setAccStatus] = useState(null);
  const [remarks, setRemarks] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const cookieRes = await fetch(`/api/account/getCookie`)
        const cookie = await cookieRes.json()
        const userRes = await fetch(`/api/account/getOneAccount?id=${cookie.id}`)
        const user = await userRes.json();
        setUserName(user[0].username);
        setEmailAddress(user[0].email);
        setUContactNo(user[0].contactno);
        setAccStatus(user[0].status);
        setRemarks(user[0].remarks);
      } catch (error) {
        alert(`Error: ${error.message}. Press 'OK' to refresh.`);
        window.location.reload(false);
      }
    }
    getData();
  }, [])

  const updateAccount = async () => {
    try {
      const response = await fetch(`/api/account/updateAccount`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          email: emailAddress,
          username: userName,
          contactno: UContactNo,
          status: accStatus,
          remarks: remarks
        }),
      });
      if (response.status == 200) {
        alert("User update successful!");
        window.location = "/user_profile";
      } else {
        alert("User update failed!");
        window.location.reload(false);
      }
    } catch (error) {
      alert(`Error: ${error.message}. Press 'OK' to refresh.`);
      window.location.reload(false);
    } finally {
      setIsLoading(false);
    }
  }

  const handleBack = async () => {
    window.location = "/user_profile"
  }
  const handleCancel = async () => {
    window.location = "/user_profile"
  }
  const handleSave = async () => {
    setIsLoading(true);
    await updateAccount();
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="um_user_main-content">
      {isLoading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
          <p className="spinner-text">Saving, please wait...</p>
        </div>
      )}
      <div className='um_user_header-container'>
        <div className='um_user_title'>
          <h1>Profile</h1>
        </div>
        <div className="um_user_header">
          <span className="um_user_back-button" onClick={handleBack}>← Back</span>
          <div className="um_user_form-actions">
            <button className="um_user_cancel-button" onClick={handleCancel}>Cancel</button>
            <button className="um_user_save-button" onClick={handleSave} disabled={isLoading}>Save</button>
          </div>
        </div>
      </div>
      <div className='um_user_body'>
        <div className='um_user_side-by-side'>
          <div className='um_user_side_description'>
            <h2>User Details</h2>
            <p>Enter user name, address and contact information.</p>
          </div>
          <div className="um_user_form">
            <div className="um_user_form-group">
              <label>User Name</label>
              <input
                type="text"
                placeholder="User Name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="um_user_form-group">
              <label>Contact No.</label>
              <input
                type="text"
                placeholder="Contact No."
                value={UContactNo}
                onChange={(e) => setUContactNo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='um_user_side-by-side'>
          <div className='um_user_side_description'>
            <h2>Account Status</h2>
            <p>Activate or suspend this particular accounts.</p>
          </div>
          <div className="um_user_form">
            <div className="um_user_form-group">
              <label>Status of this account<span style={{ color: 'red' }}>*</span></label>
              <select onChange={(e) => setAccStatus(e.target.value)}>
                <option value={accStatus} selected hidden>{capitalizeFirstLetter(accStatus)}</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="um_user_form-group">
              <label>Remarks</label>
              <input
                type="text"
                placeholder="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserForm = () => (
  <div className="um_user_page">
    <Sidebar />
    <UsersForm />
  </div>
);

export default UserForm;
