import React from 'react';
import { Link } from 'react-router-dom';
import '../CSS/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-side-by-side">
        <div className="footer-logo">
          <span><b>Singapore Ancestry</b></span>
          {/* <img src="/path-to-your-logo.png" /> */}
        </div>
        <div className="footer-navbar">
          <ul>
            <li><Link to="/obituary">Obituary</Link></li>
            <li><Link to="/gallery">Gallery</Link></li>
          </ul>
        </div>
      </div>
      <hr />
      <p>&copy; {new Date().getFullYear()} Name. All rights reserved.</p>
    </footer>
  );
}

export default Footer;