import React from 'react';
import { Link } from 'react-router-dom';
import '../CSS/MemoriesGallery.css';

const images = [
    { src: 'asian-elderly-man-feeling-happy-smiling-looking-camera-while-relax-kitchen-home-lifestyle-senior-men-home-concept.jpg', alt: 'memory 1' },
    { src: 'lovely-portrait-father-son-celebration-father-s-day.jpg', alt: 'memory 2' },
    { src: 'grandmother-playing-with-granddaughter-outdoors-park.jpg', alt: 'memory 3' },
    { src: 'medium-shot-family-with-delicious-chocolate.jpg', alt: 'memory 4' },
    { src: 'medium-shot-old-woman-library.jpg', alt: 'memory 5' },
    { src: 'mother-daughter-sitting-floor.jpg', alt: 'memory 6' },
    { src: 'happy-senior-old-asian-lover-couple-holding-smartphone-looking-cellphone-screen-laughing-casual-relaxing-sit-sofa-together-smiling-elder-mature-grandparents-family-embracing-lifestyle.jpg', alt: 'memory 7' },
    { src: 'fist-bump-asian-father-son.jpg', alt: 'memory 8' },
    { src: 'asian-elderly-couple-dancing-together-while-listen-music-living-room-home-sweet-couple-enjoy-love-moment-while-having-fun-when-relaxed-home.jpg', alt: 'memory 9' },
];

const MemoriesGallery = () => {
    return (
      <div className="MemoriesGallery">
        <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <h1 className="memoriesGallery-title">Memories with the loves</h1>
        <p className="memoriesGallery-subtitle">Browse a collection of cherished memories with your loved ones through photos, videos, and stories shared by family and friends.</p>
        <div className="memoriesGallery-gallery">
          {images.map((image, index) => (
            <div className="memoriesGallery-image-container" key={index}>
                <img src={image.src} alt={image.alt} className="memoriesGallery-image" />
            </div>
          ))}
          <Link to="/gallery" className="memoriesGallery-link">Check out for more</Link>
        </div>
      </div>
    );
};

export default MemoriesGallery;