import { Link } from 'react-router-dom';
import '../CSS/JoinUs.css';

function JoinUs() {
  return (
    <div className="JoinUs">
      <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <div className="joinUs-big-text">
        <span>Create an Online Obituary</span>
      </div>
      <div className="joinUs-small-text">
        <span>Join our community and start creating online obituary for your loved ones today.</span>
      </div>
      <div className='joinUs-getStartedBtn'>
        <Link to="/account"><button>Get started</button></Link>
      </div>
    </div>
  );
}

export default JoinUs;