import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './CSS/Obituary.css';
// import LinkPreview from './Component/LinkPreview';

const Obituary = () => {
    const [copied, setCopied] = useState(false);
    const [recentObituaries, setRecentObituaries] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState([]);
    const [filteredPersons, setFilteredPersons] = useState([]);
    const [query, setQuery] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await fetch("/api/obituary/getPublishedObituary");
                const data = await result.json();
                const updatedObituaries = await Promise.all(
                    data.map(async (obituary) => {
                        const propicResponse = await fetch(`/api/obituary/getPic?path=${obituary.propic}`);
                        const pictureResponse = await fetch(`/api/obituary/getPic?path=${obituary.picture}`);
                        let uAddress = "";
                        obituary.address1 ? uAddress=`${obituary.address1},` : uAddress="";
                        obituary.address2 ? uAddress=`${uAddress} ${obituary.address2},` : uAddress=`${uAddress}`;
                        obituary.postcode ? uAddress=`${uAddress} ${obituary.postcode},` : uAddress=`${uAddress}`;
                        obituary.city ? uAddress=`${uAddress} ${obituary.city},` : uAddress=`${uAddress}`;
                        obituary.state ? uAddress=`${uAddress} ${obituary.state},` : uAddress=`${uAddress}`;
                        obituary.country ? uAddress=`${uAddress} ${obituary.country}` : uAddress=`${uAddress}`;

                        const structure = {
                            id: obituary.id,
                            proimage: propicResponse.url,
                            name: obituary.name,
                            dob: obituary.birthdate,
                            dod: obituary.deathdate,
                            image: pictureResponse.url,
                            uid: obituary.uid,
                            uname: obituary.uname,
                            ucontactno: obituary.ucontactno,
                            acontactno: obituary.acontactno,
                            uaddress: uAddress
                        };
                        return structure;
                    })
                );
                setRecentObituaries(updatedObituaries);
                setFilteredPersons(updatedObituaries);
                const queryParams = new URLSearchParams(window.location.search);
                const idParam = queryParams.get('id');
                const searchParam = queryParams.get('search');
                const startDateParam = queryParams.get('startDate');
                const endDateParam = queryParams.get('endDate');
                if(idParam) {
                    const specificData = await updatedObituaries.find((element) => element.id == idParam);
                    if(specificData) {
                        const structure = [{
                            id: specificData.id,
                            proimage: specificData.proimage,
                            name: specificData.name,
                            dob: specificData.dob,
                            dod: specificData.dod,
                            image: specificData.image,
                            uid: specificData.uid,
                            uname: specificData.uname,
                            ucontactno: specificData.ucontactno,
                            acontactno: specificData.acontactno,
                            uaddress: specificData.uaddress
                        }];
                        setFilteredPersons(structure);
                        setSelectedPerson(structure[0])
                    } else {
                        alert("Obituary does not exist")
                        window.location = "/obituary";
                    }
                } else if (searchParam) {
                    const specificData = updatedObituaries.filter((element) => element.name.toLowerCase().includes(searchParam.toLowerCase()));
                    if(specificData.length > 0) {
                        let structure = []
                        specificData.map(async (specData) => {
                            structure.push({
                                id: specData.id,
                                proimage: specData.proimage,
                                name: specData.name,
                                dob: specData.dob,
                                dod: specData.dod,
                                image: specData.image,
                                uid: specData.uid,
                                uname: specData.uname,
                                ucontactno: specData.ucontactno,
                                acontactno: specData.acontactno,
                                uaddress: specData.uaddress
                            });
                        })
                        setFilteredPersons(structure);
                        setSelectedPerson(structure[0])
                    } else {
                        alert("Does not have any name match")
                        window.location = "/obituary";
                    }
                } else if (startDateParam || endDateParam) {
                    let specificData
                    if (startDateParam && endDateParam) {
                        specificData = updatedObituaries.filter((element) => (element.dod >= startDateParam && element.dod <= endDateParam));
                    } else if (startDateParam && !endDateParam) {
                        specificData = updatedObituaries.filter((element) => (element.dod >= startDateParam));
                    } else if (!startDateParam && endDateParam) {
                        specificData = updatedObituaries.filter((element) => (element.dod <= endDateParam));
                    }
                    
                    if(specificData.length > 0) {
                        let structure = []
                        specificData.map(async (specData) => {
                            structure.push({
                                id: specData.id,
                                proimage: specData.proimage,
                                name: specData.name,
                                dob: specData.dob,
                                dod: specData.dod,
                                image: specData.image,
                                uid: specData.uid,
                                uname: specData.uname,
                                ucontactno: specData.ucontactno,
                                acontactno: specData.acontactno,
                                uaddress: specData.uaddress
                            });
                        })
                        setFilteredPersons(structure);
                        setSelectedPerson(structure[0])
                    } else {
                        alert("Does not have any obituary for these date")
                        window.location = "/obituary";
                    }
                } else {
                    setSelectedPerson(updatedObituaries[0]);
                }
            } catch (error) {
                console.error("Error fetching obituaries:", error);
            } finally {
                setIsLoading(false);
            }
        }
        getData();
    }, [])

    if (isLoading) {
        return <p>Loading obituaries...</p>;
    }

    const { protocol, hostname, port } = window.location;
    const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    const newLink = `${baseUrl}/obituary?id=${selectedPerson.id}`;

    const handleCopy = () => {
        navigator.clipboard.writeText(newLink)
        .then(() => {
            setCopied(true);  // Update state to indicate success
            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        })
        .catch(err => {
            console.error("Failed to copy: ", err);
        });
    };

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const handleSearch = () => {
        window.location = `/obituary?search=${query}`;
    };

    const handleApply = () => {
        if (startDate && endDate) {
            window.location = `/obituary?startDate=${startDate}&endDate=${endDate}`;
        } else if (startDate && !endDate) {
            window.location = `/obituary?startDate=${startDate}`;
        } else if (!startDate && endDate) {
            window.location = `/obituary?endDate=${endDate}`;
        } else {
            alert("Please enter at least one date to filter")
        }
        
    };

    const DynamicPart = () => {
        if (window.innerWidth >= 768) {
          return (
            <div>
                <div className="obituary-details">
                    <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
                    <div className="obituary-page-recent-obituaries">
                        <button className="recent-obituaries-nav-button"><img src='left arrow.png' alt='an arrow'/></button>
                        <ul className="obituary-list">
                            {filteredPersons.map((obit, index) => (
                                <li 
                                    key={index} 
                                    className={`obituary-item ${obit.name === selectedPerson.name ? 'active' : ''}`} 
                                    onClick={() => setSelectedPerson(obit)}
                                >
                                    <div className="obituary-card" key={index}>
                                        <img src={obit.proimage} alt={obit.name} className="obituary-image" />
                                        <div className='obituary-card-info'>
                                            <span className='obituary-card-info-span-1'>In loving memory of</span>
                                            <h3 className='obituary-card-h3'>{obit.name}</h3>
                                            <p className='obituary-card-p'>{obit.dob} - {obit.dod}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <button className="recent-obituaries-nav-button"><img src='right arrow.png' alt='an arrow'/></button>
                    </div>
                </div>
                <div className='obituary-second-part'>
                    <img src={selectedPerson.image} alt="obituary" />
                    <div className='obituary-space-between2'>
                        <div className='obituary-waze-link'>
                            <span>Waze location: <a className='obituary-link' href='https://waze.com/toapayohlor5s'>https://waze.com/</a></span>
                        </div>
                        <div>
                            <button onClick={handleCopy} className='obituary-share-btn'><img src='sharebtn.png' alt='sharebtn' /></button>
                            {copied && <p>Copied to clipboard!</p>}
                        </div>
                    </div>
                </div>
                <div className='obituary-bottom-info'>
                    <span>名字 Name: {selectedPerson.uname}</span>
                    <span>电话 Tel: {selectedPerson.ucontactno}</span>
                    <span>手提 Mobile: {selectedPerson.acontactno}</span>
                    <span>地址 Address: {selectedPerson.uaddress}</span>
                    <Link to="/undertaker" state={{ props: selectedPerson.uid }}><img src='viewprofilebtn.png' className='obituary-view-profile' alt='viewpbtn' /></Link>
                </div>
            </div>
          )
        } else {
          return (
            <div>
                <div className="obituary-details">
                    <div className="obituary-page-recent-obituaries">
                        <ul className="obituary-list">
                            {filteredPersons.map((obit, index) => (
                                <li 
                                    key={index} 
                                    className={`obituary-item ${obit.name === selectedPerson.name ? 'active' : ''}`} 
                                    onClick={() => setSelectedPerson(obit)}
                                >
                                    <div className="obituary-card" key={index}>
                                        <img src={obit.proimage} alt={obit.name} className="obituary-image" />
                                        <div className='obituary-card-info'>
                                            <h3 className='obituary-card-h3'>{obit.name}</h3>
                                            <span className='obituary-card-p'>{obit.dob} - {obit.dod}</span>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='recent-obituaries-nav-button-container'>
                        <button className="recent-obituaries-nav-button"><img src='left arrow.png' alt='an arrow' /></button>
                        <button className="recent-obituaries-nav-button"><img src='right arrow.png' alt='an arrow' /></button>
                    </div>
                </div>
                <div className='obituary-second-part'>
                    <img src={selectedPerson.image} alt="obituary" />
                    <div className='obituary-space-between2'>
                        <div className='obituary-waze-link'>
                            <span>Waze location: <a className='obituary-link' href='https://waze.com/toapayohlor5s'>https://waze.com/</a></span>
                        </div>
                        <div>
                            <button onClick={handleCopy} className='obituary-share-btn'><img src='sharebtn.png' alt='sharebtn' /></button>
                            {copied && <p>Copied to clipboard!</p>}
                        </div>
                    </div>
                </div>
                <div className='obituary-bottom-info'>
                    <span>名字 Name: {selectedPerson.uname}</span>
                    <span>电话 Tel: {selectedPerson.ucontactno}</span>
                    <span>手提 Mobile: {selectedPerson.acontactno}</span>
                    <span>地址 Address: {selectedPerson.uaddress}</span>
                    <Link to="/undertaker" state={{ props: selectedPerson.uid }}><img src='viewprofilebtn.png' className='obituary-view-profile' alt='viewpbtn' /></Link>
                </div>
            </div>
          )
        }
    }
    return (
        <div className="obituary-page">
          <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
          <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
          <div className='obituary-space-between'>
            <h1 className='obituary-h1'>Obituary</h1>
            <span className='obituary-text'>Search for an obituary to find and remember your loved one. Enter a name to view details, tributes, and memories shared by family and friends.</span>
            <div className='obituary-side-by-side'>
                <input type="text" value={query} onChange={handleInputChange} placeholder="search" className='obituary-search-bar' />
                <button onClick={handleSearch} className='obituary-search-button'>Search</button>
                <div className='obituary-inputContainer'>
                    <label className='obituary-label'>Start Date:</label>
                    <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className='obituary-inputDate'
                    />
                    <label className='obituary-label'>End Date:</label>
                    <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className='obituary-inputDate'
                    />
                    <button onClick={handleApply} className='obituary-applyButton'>Apply</button>
                </div>
            </div>
          </div>
          <DynamicPart />
        </div>
    );
};

export default Obituary;
