import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './CSS/UndertakerProfile.css';

const UndertakerProfile = () => {
  const [undertaker, setUndertaker] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');

  const location = useLocation();
  const state = location.state;
  useEffect(() => {
    const getData = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const searchParam = queryParams.get('search');
        if (state) {
          const result = await fetch(`/api/undertaker/getSpecificUndertaker?id=${state.props}`);
          const data = await result.json();
          const updatedUndertaker = await Promise.all(
            data.map(async (unit) => {
              let uAddress = "";
              unit.address1 ? uAddress=`${unit.address1},` : uAddress="";
              unit.address2 ? uAddress=`${uAddress} ${unit.address2},` : uAddress=`${uAddress}`;
              unit.postcode ? uAddress=`${uAddress} ${unit.postcode},` : uAddress=`${uAddress}`;
              unit.city ? uAddress=`${uAddress} ${unit.city},` : uAddress=`${uAddress}`;
              unit.state ? uAddress=`${uAddress} ${unit.state},` : uAddress=`${uAddress}`;
              unit.country ? uAddress=`${uAddress} ${unit.country}` : uAddress=`${uAddress}`;

              const structure = {
                name: unit.name,
                address: uAddress,
                contactno: unit.contactno,
                service: unit.service,
                aname: unit.username,
                acontactno: unit.acontactno,
              };
              return structure;
            })
          );
          setUndertaker(updatedUndertaker);
        } else {
          const result = await fetch("/api/undertaker/getAllUndertaker");
          const data = await result.json();
          const updatedUndertaker = await Promise.all(
            data.map(async (unit) => {
              let uAddress = "";
              unit.address1 ? uAddress=`${unit.address1},` : uAddress="";
              unit.address2 ? uAddress=`${uAddress} ${unit.address2},` : uAddress=`${uAddress}`;
              unit.postcode ? uAddress=`${uAddress} ${unit.postcode},` : uAddress=`${uAddress}`;
              unit.city ? uAddress=`${uAddress} ${unit.city},` : uAddress=`${uAddress}`;
              unit.state ? uAddress=`${uAddress} ${unit.state},` : uAddress=`${uAddress}`;
              unit.country ? uAddress=`${uAddress} ${unit.country}` : uAddress=`${uAddress}`;

              const structure = {
                name: unit.name,
                address: uAddress,
                contactno: unit.contactno,
                service: unit.service,
                aname: unit.username,
                acontactno: unit.acontactno,
              };
              return structure;
            })
          );
          if (searchParam) {
            const specificData = updatedUndertaker.filter((element) => element.name.toLowerCase().includes(searchParam.toLowerCase()));
            if(specificData.length > 0) {
              setUndertaker(specificData)
            } else {
              alert("Undertaker does not exist")
              window.location = "/undertaker";
            }
          } else {
            setUndertaker(updatedUndertaker);
          }
        }
      } catch (error) {
        console.error("Error fetching undertaker:", error);
      } finally {
        setIsLoading(false);
      }
    }
    getData();
  }, [])

  if (isLoading) {
    return <p>Loading undertakers...</p>;
  }

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    window.location = `/undertaker?search=${query}`;
  };

  const DynamicPart = () => {
    return (
      <div>
        <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
        {undertaker.map((unit) => (
          <div className="uprofile-details">
            <div className="uprofile-page-recent-obituaries">
              <div><span>Company Name: {unit.name}</span></div>
              <div><span>Tel: {unit.contactno}</span></div>
              <div><span>Address: {unit.address}</span></div>
              <div><span>Service Provided: {unit.service}</span></div>
              <div><span>PIC Name: {unit.aname}</span></div>
              <div><span>Mobile: {unit.acontactno}</span></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="uprofile-page">
      <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <div className='uprofile-space-between'>
        <h1 className='uprofile-h1'>Undertaker</h1>
        {/* <span className='uprofile-text'>Search for an uprofile to find and remember your loved one. Enter a name to view details, tributes, and memories shared by family and friends.</span> */}
        <div className='uprofile-side-by-side'>
          <input type="text" value={query} onChange={handleInputChange} placeholder="search" className='uprofile-search-bar' />
          <button onClick={handleSearch} className='uprofile-search-button'>Search</button>
        </div>
      </div>
      <DynamicPart />
    </div>
  );
};

export default UndertakerProfile;
