import React, { useState, useEffect } from 'react';
import '../CSS/UserForm.css';
import Sidebar from '../Component/Sidebar';

const UsersForm = () => {
  const [userName, setUserName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [UContactNo, setUContactNo] = useState('');

  const [accStatus, setAccStatus] = useState(null);
  const [remarks, setRemarks] = useState('');

  const [hashPassword, setHashPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const triggerCreate = async () => {
      try {
        if(hashPassword !="") {
          const cookieRes = await fetch(`/api/account/getCookie`)
          const cookie = await cookieRes.json()
          const response = await fetch(`/api/account/createAccount`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({
              email: emailAddress,
              password: hashPassword,
              role: "User",
              username: userName,
              contactno: UContactNo,
              status: accStatus,
              remarks: remarks,
              createdBy: cookie.id
            }),
          });
          if (response.status == 200) {
            alert("User created successful!");
            window.location = "/user_manage";
          }
          else {
            alert("User creation failed!");
            window.location.reload(false);
          }
        }
      } catch (error) {
        alert(`Error: ${error.message}. Press 'OK' to refresh.`);
        window.location.reload(false);
      } finally {
        setIsLoading(false);
      }
    }
    triggerCreate();
  }, [hashPassword])

  const hashPW = async () => {
    try {
      const response = await fetch(`/api/account/hashPW?password=${password}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setHashPassword(data);
    } catch (error) {
      alert("Error fetching hashed password:", error);
    }
  }

  const handleBack = async () => {
    window.location = "/user_manage"
  }
  const handleCancel = async () => {
    window.location = "/user_manage"
  }
  const handleSave = async () => {
    if(emailAddress && password && cPassword && accStatus) {
      if (password !== cPassword) {
        alert("Passwords do not match!");
        return;
      }
      const getAcc = await fetch(`/api/account/specificAccount?email=${emailAddress}`)
      const account = await getAcc.json()
      if(account.length == 0) {
        setIsLoading(true);
        await hashPW();
      } else alert("Email already registered")
    } else alert("Please enter all required fields")
  };

  return (
    <div className="um_user_main-content">
      {isLoading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
          <p className="spinner-text">Saving, please wait...</p>
        </div>
      )}
      <div className='um_user_header-container'>
        <div className='um_user_title'>
          <h1>Create User</h1>
        </div>
        <div className="um_user_header">
          <span className="um_user_back-button" onClick={handleBack}>← Back</span>
          <div className="um_user_form-actions">
            <button className="um_user_cancel-button" onClick={handleCancel}>Cancel</button>
            <button className="um_user_save-button" onClick={handleSave} disabled={isLoading}>Save</button>
          </div>
        </div>
      </div>
      <div className='um_user_body'>
        <div className='um_user_side-by-side'>
          <div className='um_user_side_description'>
            <h2>User Details</h2>
            <p>Enter user name, address and contact information.</p>
          </div>
          <div className="um_user_form">
            <div className="um_user_form-group">
              <label>User Name</label>
              <input
                type="text"
                placeholder="User Name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="um_user_form-group">
              <label>Email Address<span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                placeholder="Email Address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </div>
            <div className="um_user_form-group">
              <label>Password<span style={{ color: 'red' }}>*</span></label>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="um_user_form-group">
              <label>Confirm Password<span style={{ color: 'red' }}>*</span></label>
              <input
                type="password"
                placeholder="Confirm Password"
                value={cPassword}
                onChange={(e) => setCPassword(e.target.value)}
              />
            </div>
            <div className="um_user_form-group">
              <label>Contact No.</label>
              <input
                type="text"
                placeholder="Contact No."
                value={UContactNo}
                onChange={(e) => setUContactNo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='um_user_side-by-side'>
          <div className='um_user_side_description'>
            <h2>Account Status</h2>
            <p>Activate or suspend this particular accounts.</p>
          </div>
          <div className="um_user_form">
            <div className="um_user_form-group">
              <label>Status of this account<span style={{ color: 'red' }}>*</span></label>
              <select onChange={(e) => setAccStatus(e.target.value)}>
                <option value="" selected hidden>Select an option</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="um_user_form-group">
              <label>Remarks</label>
              <input
                type="text"
                placeholder="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserForm = () => (
  <div className="um_user_page">
    <Sidebar />
    <UsersForm />
  </div>
);

export default UserForm;
