import React from 'react';

const EmptyState = () => (
  <div style={{ textAlign: 'center', marginTop: '20px', fontFamily: "Josefin Sans" }}>
    <img src="empty-state-icon.png" alt="Empty State" />
    <p>Oops, it is empty. Click "Create" button to register new.</p>
  </div>
);

export default EmptyState;
