import './CSS/Home.css';
import Intro from './Home/Intro';
import Steps from './Home/Steps';
import RecentObituaries from './Home/RecentObituaries';
import MemoriesGallery from './Home/MemoriesGallery';
import UserReview from './Home/UserReview';
import JoinUs from './Home/JoinUs';
import PartnerContact from './Home/PartnerContact';

function Home() {
  return (
    <div className="Home">
      <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <Intro />
      <Steps />
      <RecentObituaries />
      <MemoriesGallery />
      <UserReview />
      <JoinUs />
      <PartnerContact />
    </div>
  );
}

export default Home;