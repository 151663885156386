import React, { useState, useEffect } from 'react';
import '../CSS/Account.css';

const RegisterScreen = ({ onBack }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [hashPassword, setHashPassword] = useState('');

  useEffect(() => {
    const triggerCreate = async () => {
      if(hashPassword !="") {
        const response = await fetch(`/api/account/createAccount`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify({
            email: email,
            password: hashPassword,
            role: "User",
            username: name,
            contactno: "",
            status: "active",
            remarks: "",
            createdBy: 1
          }),
        });
        if (response.status == 200) {
          alert("Registration successful!");
          window.location = "/account";
        }
        else {
          alert("Registration failed!");
          window.location.reload(false);
        }
      }
    }
    triggerCreate();
  }, [hashPassword])

  const hashPW = async () => {
    try {
      const response = await fetch(`/api/account/hashPW?password=${password}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setHashPassword(data);
    } catch (error) {
      alert("Error fetching hashed password:", error);
    }
  }

  const handleRegister = async () => {
    if(email && password && confirmPassword) {
      if (password !== confirmPassword) {
        alert("Passwords do not match!");
        return;
      }
      const getAcc = await fetch(`/api/account/specificAccount?email=${email}`)
      const account = await getAcc.json()
      if(account.length == 0) {
        await hashPW();
      } else alert("Email already registered")
    } else alert("Please enter all required fields")
  };
  return (
    <div className="register-screen">
      <header className="header_acc">
        <h2 className='h2_acc'>Get Started With Singapore Ancestry</h2>
      </header>
      <div className="content_acc">
        <div className='flex_acc-back_btn'>
          <button onClick={onBack} className="back-btn_acc">Back</button>
        </div>
        <h3 className='h3_acc'>Welcome to Singapore Ancestry. Please create an account.</h3>

        <div className='input_acc'>
          <input
            className='inputbox_acc'
            type="text"
            placeholder="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <input
            className='inputbox_acc'
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            className='inputbox_acc'
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <input
            className='inputbox_acc'
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button onClick={handleRegister} className="btn-primary_acc">Register</button>
      </div>
    </div>
  );
};

export default RegisterScreen;
