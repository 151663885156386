import React, { useEffect, useState } from 'react';
import '../CSS/RecentObituaries.css';

const RecentObituaries = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [recentObituaries, setRecentObituaries] = useState([]);

  useEffect(() => {
    const getData = async () => {
        try {
            const result = await fetch("/api/obituary/getPublishedObituary");
            const data = await result.json();
            const updatedObituaries = await Promise.all(
                data.map(async (obituary) => {
                    const propicResponse = await fetch(`/api/obituary/getPic?path=${obituary.propic}`);

                    const structure = {
                        id: obituary.id,
                        proimage: propicResponse.url,
                        name: obituary.name,
                        dob: obituary.birthdate,
                        dod: obituary.deathdate
                    };
                    return structure;
                })
            );
            setRecentObituaries(updatedObituaries);
        } catch (error) {
            console.error("Error fetching obituaries:", error);
      } finally {
        setIsLoading(false);
      }
    }
    getData();
  }, [])

  if (isLoading) {
    return <p>Loading obituaries...</p>;
  }

  const ForArrow = () => {
    if (window.innerWidth >= 768) {
      return (
        <div className="recent-obituaries-obituaries-container">
          <button className="recent-obituaries-nav-button"><img src='left arrow.png' alt='an arrow' /></button>
          {recentObituaries.map((obit, index) => (
            <div className="recent-obituaries-obituary-card" key={index}>
              <img src={obit.proimage} alt={obit.name} className="recent-obituaries-obituary-image" />
              <h3>{obit.name}</h3>
              <p className="recent-obituaries-obituary-dates">{obit.dob} - {obit.dod}</p>
            </div>
          ))}
          <button className="recent-obituaries-nav-button"><img src='right arrow.png' alt='an arrow' /></button>
        </div>
      )
    } else {
      return (
        <div className="recent-obituaries-obituaries-container">
          {recentObituaries.map((obit, index) => (
            <div className="recent-obituaries-obituary-card" key={index}>
              <img src={obit.proimage} alt={obit.name} className="recent-obituaries-obituary-image" />
              <h3>{obit.name}</h3>
              <p className="recent-obituaries-obituary-dates">{obit.dob}-{obit.dod}</p>
            </div>
          ))}
          <div className='recent-obituaries-nav-button-container'>
            <button className="recent-obituaries-nav-button"><img src='left arrow.png' alt='an arrow' /></button>
            <button className="recent-obituaries-nav-button"><img src='right arrow.png' alt='an arrow' /></button>
          </div>
        </div>
      )
    }
  }

  return (
      <div className="recent-obituaries">
        <link href='https://fonts.googleapis.com/css?family=Josefin Sans' rel='stylesheet'></link>
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <div className='recent-obituaries-text'>
          <h2 className='recent-obituaries-h2'>Recent Obituaries</h2>
          <p className='recent-obituaries-p'>View the latest obituaries, read life stories, and share tributes and condolences.</p>
          <p className='recent-obituaries-p'><a href="/obituary" className="recent-obituaries-see-all">See all obituaries</a></p>
        </div>
        <div className='place-in-column'>
          <ForArrow />
        </div>
      </div>
  );
};

export default RecentObituaries;