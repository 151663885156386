import React, { useState } from 'react';
import './CSS/Account.css';
import WelcomeScreen from './Account/WelcomeScreen';
import RegisterScreen from './Account/RegisterScreen';
import LoginScreen from './Account/LoginScreen';

const App = () => {
  const [screen, setScreen] = useState('welcome');

  const handleRegister = () => setScreen('register');
  const handleBack = () => setScreen('welcome');
  const handleLogin = () => setScreen('login');

  return (
    <>
      {screen === 'welcome' && <WelcomeScreen onGetStarted={handleRegister} onLogin={handleLogin} />}
      {screen === 'register' && <RegisterScreen onBack={handleBack} />}
      {screen === 'login' && <LoginScreen onBack={handleBack} />}
    </>
  );
};

export default App;
