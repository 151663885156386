import React, { useState, useEffect } from 'react';
import '../CSS/Account.css';

const LoginScreen = ({ onBack }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const checkCredential = async (email,password) => {
    try{
      if(email && password) {
        const getAcc = await fetch(`/api/account/specificAccount?email=${email}`)
        const account = await getAcc.json()
        if(account.length) getHash(email, password, account[0].id, account[0].password, account[0].role)
        else alert("Wrong Email")
      } else alert("Please enter both Email and Password")
    } catch(error) {
      alert("CheckCredential failed... Press 'OK' to refresh");
      window.location.reload(false);
    }
  };

  async function getHash(email, password, id, hash, role) {
    try{
      const result = await fetch(`/api/account/login?id=${id}&email=${email}&password=${password}&hash=${hash}&role=${role}`)
      const compare = await result.json()
      if(compare[0].result === true) {
        if(role == "User") {
          window.location = "/obituary_manage";
        } else {
          window.location = "/user_manage";
        }
        
      } else {
        alert("Wrong Password")
      }
    } catch(error) {
      alert("GetHash failed... Press 'OK' to refresh");
      window.location.reload(false);
    }
  };

  const handleLogin = async () => {
    await checkCredential(email,password);
  };

  return (
    <div className="login-screen">
      <header className="header_acc">
        <h2 className='h2_acc'>Get Started With Singapore Ancestry</h2>
      </header>
      <div className="content_acc">
        <div className='flex_acc-back_btn'>
          <button onClick={onBack} className="back-btn_acc">Back</button>
        </div>
        <h3 className='h3_acc'>Hi, welcome to Singapore Ancestry.</h3>

        <div className='input_acc'>
          <input
            className='inputbox_acc'
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className='inputbox_acc'
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        
        <button onClick={handleLogin} className="btn-primary_acc">Login</button>
        {/* <p>Or</p>
        <div className="social-login-icons">
          { Replace with actual icons }
          <span>Apple</span>
          <span>Google</span>
          <span>Facebook</span>
        </div> */}
      </div>
    </div>
  );
};

export default LoginScreen;
