import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './Home';
import Obituary from './Obituary';
import Gallery from './Gallery';
import Undertaker from './Undertaker';
import Header from './Component/Header';
import Footer from './Component/Footer';
import FullScreenSlideshow from './FullScreenSlideshow';
import Account from './Account';

import SettingPage from './AfterLogin/SettingPage';
import UserManagement from './AfterLogin/UserManagement';
import UserForm from './AfterLogin/UserForm';
import UserProfile from './AfterLogin/UserProfile';
import UndertakerManagement from './AfterLogin/UndertakerManagement';
import UndertakerForm from './AfterLogin/UndertakerForm';
import UndertakerProfile from './AfterLogin/UndertakerProfile';
import ObituaryManagement from './AfterLogin/ObituaryManagement';
import ObituaryForm from './AfterLogin/ObituaryForm';
// import Path2 from './Path2';

function App() {
  const location = useLocation();

  const noHeaderFooterRoutes = ['/fss', 'setting'];
  const noFooterRoutes = ['/account','/undertaker'];

  const header2Footer2Routes = ['/setting', '/user_manage', '/undertaker_manage', '/undertaker/create', '/user/create', '/obituary_manage', '/obituary/create', '/undertaker_profile', '/user_profile'];

  return (
    <>
      {!noHeaderFooterRoutes.includes(location.pathname) && !header2Footer2Routes.includes(location.pathname) && <Header />}
      
      <Routes>
        <Route path="/fss" element={<FullScreenSlideshow />} />
        <Route path="/account" element={<Account />} />
        <Route path="/" element={<Home />} />
        <Route path="/obituary" element={<Obituary />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/undertaker" element={<Undertaker />} />

        <Route path="/setting" element={<SettingPage />} />
        <Route path="/user_manage" element={<UserManagement />} />
        <Route path="/user/create" element={<UserForm />} />
        <Route path="/user_profile" element={<UserProfile />} />
        <Route path="/undertaker_manage" element={<UndertakerManagement />} />
        <Route path="/undertaker/create" element={<UndertakerForm />} />
        <Route path="/undertaker_profile" element={<UndertakerProfile />} />
        <Route path="/obituary_manage" element={<ObituaryManagement />} />
        <Route path="/obituary/create" element={<ObituaryForm />} />
        
        {/* <Route path="/path1" element={<Path1 />} />
        <Route path="/path2" element={<Path2 />} /> */}
      </Routes>

      {!noHeaderFooterRoutes.includes(location.pathname) && !header2Footer2Routes.includes(location.pathname) && !noFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <div className="App">
        <App />
      </div>
    </Router>
  );
}

export default AppWrapper;