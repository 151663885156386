import React, { useState, useEffect } from 'react';
import '../CSS/UndertakerForm.css';
import Sidebar from '../Component/Sidebar';

const UndertakersForm = () => {
  const [undertakerId, setUndertakerId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [addressL1, setAddressL1] = useState('');
  const [addressL2, setAddressL2] = useState('');
  const [postCode, setPostCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [COContactNo, setCOContactNo] = useState('');
  const [service, setService] = useState('');

  const [PICName, setPICName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [PICContactNo, setPICContactNo] = useState('');

  const [accStatus, setAccStatus] = useState(null);
  const [remarks, setRemarks] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const cookieRes = await fetch(`/api/account/getCookie`)
        const cookie = await cookieRes.json()
        const undertakerRes = await fetch(`/api/undertaker/getSpecificUndertaker?id=${cookie.id}`)
        const undertaker = await undertakerRes.json();
        setPICName(undertaker[0].username);
        setEmailAddress(undertaker[0].email);
        setPICContactNo(undertaker[0].acontactno);
        setAccStatus(undertaker[0].astatus);
        setRemarks(undertaker[0].remarks);
        setUndertakerId(undertaker[0].id);
        setCompanyName(undertaker[0].name);
        setAddressL1(undertaker[0].address1);
        setAddressL2(undertaker[0].address2);
        setPostCode(undertaker[0].postcode);
        setCity(undertaker[0].city);
        setState(undertaker[0].state);
        setCountry(undertaker[0].country);
        setCOContactNo(undertaker[0].contactno);
        setService(undertaker[0].service);
      } catch (error) {
        alert(`Error: ${error.message}. Press 'OK' to refresh.`);
        window.location.reload(false);
      }
    }
    getData();
  }, [])

  const updateAccount = async () => {
    try {
      const response = await fetch(`/api/account/updateAccount`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          email: emailAddress,
          username: PICName,
          contactno: PICContactNo,
          status: accStatus,
          remarks: remarks
        }),
      });
      if (response.status == 200) {
        const response2 = await fetch(`/api/undertaker/update`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify({
            id: undertakerId,
            name: companyName,
            address1: addressL1,
            address2: addressL2,
            postcode: postCode,
            city: city,
            state: state,
            country: country,
            contactno: COContactNo,
            service: service
          }),
        });
        if (response2.status == 200) {
          alert("Undertaker update successful!");
          window.location = "/undertaker_profile";
        } else {
          alert("Undertaker update failed!");
          window.location.reload(false);
        }
      } else {
        alert("Undertaker update failed!");
        window.location.reload(false);
      }
    } catch (error) {
      alert(`Error: ${error.message}. Press 'OK' to refresh.`);
      window.location.reload(false);
    } finally {
      setIsLoading(false);
    }
  }

  const handleBack = async () => {
    window.location = "/undertaker_profile"
  }
  const handleCancel = async () => {
    window.location = "/undertaker_profile"
  }
  const handleSave = async () => {
    setIsLoading(true);
    await updateAccount();
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="um_undertaker_main-content">
      {isLoading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
          <p className="spinner-text">Saving, please wait...</p>
        </div>
      )}
      <div className='um_undertaker_header-container'>
        <div className='um_undertaker_title'>
          <h1>Profile</h1>
        </div>
        <div className="um_undertaker_header">
          <span className="um_undertaker_back-button" onClick={handleBack}>← Back</span>
          <div className="um_undertaker_form-actions">
            <button className="um_undertaker_cancel-button" onClick={handleCancel}>Cancel</button>
            <button className="um_undertaker_save-button" onClick={handleSave} disabled={isLoading}>Save</button>
          </div>
        </div>
      </div>
      <div className='um_undertaker_body'>
        <div className='um_undertaker_side-by-side'>
          <div className='um_undertaker_side_description'>
            <h2>Company Details</h2>
            <p>Enter company name, address and contact information.</p>
          </div>
          <div className="um_undertaker_form">
            <div className="um_undertaker_form-group">
              <label>Company Name</label>
              <input
                type="text"
                placeholder="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Address</label>
              <input
                type="text"
                placeholder="Line 1"
                value={addressL1}
                onChange={(e) => setAddressL1(e.target.value)}
              />
              <input
                type="text"
                placeholder="Line 2"
                value={addressL2}
                onChange={(e) => setAddressL2(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Post Code</label>
              <input
                type="text"
                placeholder="Postcode"
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>City</label>
              <input
                type="text"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>State</label>
              <input
                type="text"
                placeholder="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Country</label>
              <input
                type="text"
                placeholder="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Contact No.</label>
              <input
                type="text"
                placeholder="Contact No."
                value={COContactNo}
                onChange={(e) => setCOContactNo(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Service Provided</label>
              <textarea
                placeholder="Service Provided"
                value={service}
                title="Service Provided"
                rows="4"
                cols="30"
                maxlength="255"
                onChange={(e) => setService(e.target.value)}
                className="textarea-service"
              />
            </div>
          </div>
        </div>
        <div className='um_undertaker_side-by-side'>
          <div className='um_undertaker_side_description'>
            <h2>PIC Details</h2>
            <p>Enter PIC name, address and contact information.</p>
          </div>
          <div className="um_undertaker_form">
            <div className="um_undertaker_form-group">
              <label>Name</label>
              <input
                type="text"
                placeholder="Name"
                value={PICName}
                onChange={(e) => setPICName(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Contact No.</label>
              <input
                type="text"
                placeholder="Contact No."
                value={PICContactNo}
                onChange={(e) => setPICContactNo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='um_undertaker_side-by-side'>
          <div className='um_undertaker_side_description'>
            <h2>Account Status</h2>
            <p>Activate or suspend this particular accounts.</p>
          </div>
          <div className="um_undertaker_form">
            <div className="um_undertaker_form-group">
              <label>Status of this account<span style={{ color: 'red' }}>*</span></label>
              <select onChange={(e) => setAccStatus(e.target.value)}>
                <option value={accStatus} selected hidden>{capitalizeFirstLetter(accStatus)}</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="um_undertaker_form-group">
              <label>Remarks</label>
              <input
                type="text"
                placeholder="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main Settings Page Component
const UndertakerForm = () => (
  <div className="um_undertaker_page">
    <Sidebar />
    <UndertakersForm />
  </div>
);

export default UndertakerForm;
